<template>
    <div class="FullScreenDisplay">
        <van-popup v-model="isShow" position="right" style="width: 100%; height: 100%;">
            <iframe ref="iframe" src="/mail/content.html" v-bind:load="getMailCurrent(mId)" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="120%" height="100%"></iframe>
        </van-popup>

    </div>

</template>
<script>
// import titleMixin from '@/mixin/title'
export default {
    name: 'FullScreenDisplay',
    title: '全屏模式',
    props: {
        isShow: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        mId: {
            type: Number,
            default: function () {
                return 0
            }
        }
    },
    // mixins: [titleMixin],
    data() {
        return {
            // mId: '',
            index: 0,
            listALL: [],
            content: '',
            showDetail: this.isShow

        }
    },
    computed: {

    },
    created() {
    },
    mounted() {
        // this.pageInit()
    },
    methods: {
        loadedContent(content) {
            const _this = this
            let contentStr = ''
            if (content == '' || content == undefined) {
                // return;
                contentStr = "<div style='text-align: center;  margin-top: 20px;'>无数据</div>"
            }
            try {
                const vm = _this.$refs.iframe.contentWindow.vm
                vm.showContent(contentStr == '' ? content : contentStr)
                setTimeout(function () {
                    let autoHeight =
                        _this.$refs.iframe.contentWindow.document.documentElement
                            .scrollHeight
                    _this.$refs.iframe.style.height = autoHeight + 'px'
                }, 20)
            } catch (err) {
                console.log(' vm.showContent(_this.content) ')
            }
        },
        // setLeft() {
        //     let _this = this
        //     dd.biz.navigation.setLeft({
        //         show: true,
        //         control: true,
        //         text: '返回',
        //         onSuccess: function (result) {
        //             _this.ReturnPage()
        //         },
        //         onFail: function (err) {
        //             console.log(err)
        //         }
        //     })
        // },
        // pageInit() {
        //     if (this.$route.path == '/mail/FullScreenDisplay') {
        //         let { mId, detailObject, index, listALL } = this.$route.params
        //         this.detailObject = detailObject
        //         this.index = index
        //         this.mId = mId
        //         this.listALL = listALL
        //         this.setLeft()
        //     }
        // },
        getMailCurrent(mId) {
            let paramsData = {
                mId: mId,
                type: 'details'
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.mails_mail, { params: paramsData })
                .then((res) => {
                    if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isObject(res.data.data)) {
                        this.content = res.data.data.content
                        this.loadedContent(this.content)
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    console.log(res)
                })
        },
        ReturnPage() {

        }
    },
    watch: {
    }
}
</script>
<style lang="less" scoped>
.FullScreenDisplay {
    overflow: auto;
    height: 100%;
    width: 100%;
    position: absolute;
    color: rgba(96, 98, 102, 1);
}
</style>
